import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, EffectFade } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { StyledWrapper } from 'assets/global/styled';
import {
  StyledOffice,
  StyledOfficeContainer,
  StyledOfficeSwiper,
  StyledOfficeContent
} from './styled';
import GraphImg from 'graphcms-image';

SwiperCore.use([Pagination, EffectFade]);

const Office = ({ slider, page }) => {
  const { singleSlides } = slider;
  const { content3 } = page;
  const [firstSlide] = singleSlides;
  return (
    <StyledOffice>
      <StyledWrapper>
        <StyledOfficeContainer>
          <StyledOfficeSwiper>
            <Swiper
              slidesPerView={1}
              loop="true"
              pagination={{ clickable: true }}
              effect="fade"
            >
              {singleSlides.map((slide, index) => {
                const { image } = slide;

                return (
                  <SwiperSlide key={`slide-${index}`}>
                    <GraphImg
                      baseURI="https://media.graphassets.com"
                      image={image}
                      maxWidth={720}
                      alt={image.alt}
                      fit="clip"
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </StyledOfficeSwiper>
          <StyledOfficeContent>
            <div>
              <blockquote>
                <MarkdownPreview
                  source={firstSlide.description.markdown}
                />
              </blockquote>
              <h6>{content3}</h6>
            </div>
          </StyledOfficeContent>
        </StyledOfficeContainer>
      </StyledWrapper>
    </StyledOffice>
  );
};

export default Office;
