import styled from 'styled-components';
import { StyledWrapper } from 'assets/global/styled';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledProductConcept = styled.section`
  display: block;
  width: 100%;
  padding: 80px 0 120px;

  ${StyledWrapper} {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 980px) {
    padding: 30px 0 30px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 30px 0 30px 0;
  }
`;

export const StyledProductConceptContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
`;

export const StyledProductConceptContent = styled.div`
  width: 70%;

  p {
    font-size: 18px;
    font-weight: 300;
    color: ${colors.gray300};
    line-height: 1.8;
    font-family: ${fonts.secondary};

    strong {
      font-weight: 700;
    }

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  a {
    margin-top: 60px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledProductConceptTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 40px;
  font-family: ${fonts.primary};
  font-size: 72px;
  color: ${colors.gray300};
  line-height: 1.1;

  @media screen and (max-width: 980px) {
    font-size: 60px;
  }

  @media screen and (max-width: 768px) {
    font-size: 42px;
  }

  @media screen and (max-width: 520px) {
    font-size: 28px;
  }
`;

export const StyledProductConceptSubtitle = styled.h3`
  width: 25%;
  position: relative;
  margin: 0;
  padding: 20px 0;
  font-family: ${fonts.primary};
  font-size: 24px;
  font-weight: 300;
  color: ${colors.gray300};

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 2px;
    background-color: ${colors.gray900};
    content: '';
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 18px;
  }

  @media screen and (max-width: 520px) {
    font-size: 16px;
  }
`;
