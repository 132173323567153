import React from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { StyledWrapper } from 'assets/global/styled';
import {
  StyledPillars,
  StyledPillarsContainer,
  StyledPillarsContent,
  StyledPillarsTitle
} from './styled';

const Pillars = ({ page }) => {
  const { content1Title, content1 } = page;
  return (
    <StyledPillars>
      <StyledWrapper>
        <StyledPillarsContainer>
          <StyledPillarsTitle>{content1Title}</StyledPillarsTitle>
          <StyledPillarsContent>
            <MarkdownPreview source={content1} />
          </StyledPillarsContent>
        </StyledPillarsContainer>
      </StyledWrapper>
    </StyledPillars>
  );
};

export default Pillars;
