import React from 'react';
import Helmet from 'react-helmet';
import Intro from 'templates/About/Intro';
import Office from 'templates/About/Office';
import Pillars from 'templates/About/Pillars';
import ProductConcept from 'templates/About/ProductConcept';
import ContactUs from 'components/ContactUs';

const About = ({ page, pageContext }) => {
  const { baseURL } = pageContext;
  const { slider, contactForm, locale } = page;
  return (
    <React.Fragment>
      <Intro page={page} />
      <Office slider={slider} page={page} />
      <Pillars page={page} />
      <ProductConcept page={page} baseURL={baseURL} />
      <ContactUs
        contactForm={contactForm}
        baseURL={baseURL}
        lang={locale}
      />
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context":"https://schema.org",
              "@type":"Organization",
              "name":"ateliware",
              "legalName":"ateliware",
              "url":"http://ateliware.com/br/about",
              "logo":"https://media.graphassets.com/gJsd71c5SGh1RyV8tIrV",
              "address":{
                "@type":"PostalAddress",
                "streetAddress":"Rua Pasteur, 463",
                "addressLocality":"Curitiba",
                "addressRegion":"Paraná",
                "postalCode":"80250-080",
                "addressCountry":"Brasil"
              },
              "contactPoint":{
                "@type":"ContactPoint",
                "contactType":"Contato",
                "telephone":"[+55 41 3010-2275]",
                "email":"hello@ateliware.com"
              },
              "sameAs":[
                "https://github.com/ateliware",
                "https://www.linkedin.com/company/ateliware/",
                "https://twitter.com/ateliware",
                "https://www.youtube.com/channel/UCxPjijyar2emdKLPpOrugOw",
                "https://www.meetup.com/ateliware/",
                "https://goo.gl/maps/gLPc35mF3js7rLnL8"
              ]
            }
          `}
        </script>
      </Helmet>
    </React.Fragment>
  );
};

export default About;
