import React from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { Link } from 'gatsby';
import Button from 'components/Form/Button';
import { StyledWrapper } from 'assets/global/styled';
import {
  StyledProductConcept,
  StyledProductConceptContainer,
  StyledProductConceptContent,
  StyledProductConceptTitle,
  StyledProductConceptSubtitle
} from './styled';

const ProductConcept = ({ page, baseURL }) => {
  const {
    content2Title,
    content2Subtitle,
    content2,
    callToAction2,
    locale
  } = page;
  return (
    <StyledProductConcept>
      <StyledWrapper>
        <StyledProductConceptContainer>
          <StyledProductConceptSubtitle>
            {content2Subtitle}
          </StyledProductConceptSubtitle>
          <StyledProductConceptContent>
            <StyledProductConceptTitle>
              {content2Title}
            </StyledProductConceptTitle>
            <MarkdownPreview source={content2} />
            <Button
              as={Link}
              to={`${baseURL}services`}
              title={callToAction2}
              callToAction={true}
            >
              {callToAction2}
            </Button>
          </StyledProductConceptContent>
        </StyledProductConceptContainer>
      </StyledWrapper>
    </StyledProductConcept>
  );
};

export default ProductConcept;
