import React from 'react';
import { graphql } from 'gatsby';
import SEO from 'components/SEO';
import About from 'templates/About';
import Header from 'components/Header';
import Footer from 'components/Footer';

const AboutPage = ({
  data: {
    ateliware: { page, headerMenu, footerMenu }
  },
  pageContext
}) => {
  const { seo } = page;
  const { locale } = pageContext;
  return (
    <React.Fragment>
      <SEO
        meta_title={
          locale == 'pt'
            ? seo?.metaTitle
            : 'ateliware | We transform and simplify the business world'
        }
        meta_description={
          locale == 'pt'
            ? seo?.metaDescription
            : 'We are a software studio and we work with companies in all stages of the process, from identification to problem resolution: discovery, product concept and build.'
        }
        og_title={
          locale == 'pt'
            ? seo?.ogTitle
            : 'ateliware | We transform and simplify the business world'
        }
        og_description={
          locale == 'pt'
            ? seo?.ogDescription
            : 'We are a software studio and we work with companies in all stages of the process, from identification to problem resolution: discovery, product concept and build.'
        }
        og_type={seo?.ogType}
        og_image={seo?.ogImage?.url}
        lang={locale}
      />
      <Header menu={headerMenu} pageContext={pageContext} />
      <About page={page} pageContext={pageContext} />
      <Footer
        footerMenu={footerMenu}
        pageContext={pageContext}
        privatePolicyTitle={page.contactForm.privacyPolicyTitle}
      />
    </React.Fragment>
  );
};

export const aboutQuery = graphql`
  query AboutPageQuery($idPage: ID!, $locale: [GCMS_Locale!]!) {
    ateliware {
      page(where: { id: $idPage }, locales: $locale) {
        id
        seo {
          metaTitle
          metaDescription
          ogTitle
          ogDescription
          ogType
          ogImage {
            url
          }
        }
        locale
        slug
        title
        description
        contentTitle
        contentSubtitle
        content
        callToAction
        contentImage(locales: pt) {
          handle
          width
          height
          alt
          url
        }
        content1Title
        content1Subtitle
        content1
        callToAction1
        content1Image(locales: pt) {
          handle
          width
          height
          alt
          url
        }
        content2Title
        content2Subtitle
        content2
        callToAction2
        content2Image(locales: pt) {
          handle
          width
          height
          alt
          url
        }
        content3
        slider {
          singleSlides {
            title
            subtitle
            description {
              html
              markdown
            }
            image(locales: pt) {
              handle
              width
              height
              alt
              url
            }
          }
        }
        contactForm {
          title
          content
          nameField
          surnameField
          companyField
          emailField
          phoneField
          serviceOptionDefault
          serviceOption1
          serviceOption2
          serviceOption3
          serviceOption4
          aboutYouField
          budgetDefault
          budgetOption1
          budgetOption2
          budgetOption3
          sendButtonText
          formErrorMessage
          privacyPolicyTitle
          privacyPolicyText
          thankYouTitle
          thankYouContent
          backToSiteText
          toBlogText
        }
      }
      headerMenu: menu(where: { title: "header" }, locales: $locale) {
        locale
        callToAction
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
          subMenuItems {
            title
            slug
            locale
            goTo
            externalLink
          }
        }
      }
      footerMenu: menu(where: { title: "footer" }, locales: $locale) {
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
        }
      }
    }
  }
`;

export default AboutPage;
