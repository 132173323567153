import styled from 'styled-components';
import { StyledWrapper } from 'assets/global/styled';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledOffice = styled.section`
  display: block;
  width: 100%;
  padding: 40px 0 80px;

  ${StyledWrapper} {
    height: 100%;
  }

  @media screen and (max-width: 980px) {
    padding: 30px 0 30px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 30px 0 30px 0;
  }
`;

export const StyledOfficeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledOfficeSwiper = styled.div`
  width: 60%;
  height: 400px;

  .swiper-container {
    height: 400px;
  }

  img {
    display: block;
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    margin-bottom: 50px;
  }

  @media screen and (max-width: 520px) {
    height: 250px;

    img {
      height: 250px;
    }
  }
`;

export const StyledOfficeContent = styled.div`
  width: 35%;

  blockquote {
    margin: 0;
    padding: 0;
  }

  div {
    p {
      font-size: 18px;
      font-weight: 300;
      color: ${colors.gray300};
      line-height: 1.8;
      font-family: ${fonts.secondary};

      strong {
        font-weight: 700;
      }

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  h6 {
    padding-top: 30px;
    font-family: ${fonts.primary};
    font-size: 16px;
    font-weight: 700;
    color: ${colors.gray300};
    margin: 0;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
